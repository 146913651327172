import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { SearchFilterContentTypes } from '../../Shared/enums';
import { AudioSelectedSearchFilterOptions } from '../containers/MenuContainerInterfaces';
import {
  genres,
  moods,
  musicInstruments,
  sfxCategories,
} from '../entities/AudioSearchFilterOptions';
import AudioSearchOptions from '../entities/AudioSearchOptions';
import { SearchHistoryState } from '../utils/searchOptionsToLocation/types';
import { getDecodedSearchTerm } from '../utils/searchUtils';
import buildContentAgnosticSearchOptions from './buildContentAgnosticSearchOptions';

interface AudioSearchPageQueryParams {
  bpm_max?: number;
  bpm_min?: number;
  // Underscore-separated strings.
  categories?: string;
  has_vocals?: boolean;
  max_duration?: number;
  min_duration?: number;
  // Comma-separated strings.
  portal_artist_ids?: string;
  search_similar_id?: number;
}

export default function useAudioSearchOptionsFromLocation(): Partial<AudioSelectedSearchFilterOptions> {
  const { pathname, search, state, key } = useLocation<SearchHistoryState>();

  const selectedSearchFilterOptions = useMemo<
    Partial<AudioSelectedSearchFilterOptions>
  >(() => {
    const queryParams: AudioSearchPageQueryParams = queryString.parse(search, {
      parseBooleans: true,
      parseNumbers: true,
    });

    const updates: Partial<AudioSelectedSearchFilterOptions> =
      buildContentAgnosticSearchOptions(queryParams);

    // contentType
    switch (queryParams['media-type']) {
      case 'music':
        updates.contentType = SearchFilterContentTypes.Music;
        break;
      case 'sound-effects':
        updates.contentType = SearchFilterContentTypes.Sound_effects;
        break;
    }

    // query param names from AudioSearchQueryParams.php and SearchQueryParams.php
    // also see AudioRouteTranslator::getAllowedParams()

    // duration
    if (queryParams.min_duration) {
      updates.minDuration = queryParams.min_duration;
    }
    if (queryParams.max_duration) {
      updates.maxDuration = queryParams.max_duration;
    }

    // tempo
    if (queryParams.bpm_min) {
      updates.tempoMin = queryParams.bpm_min;
    }
    if (queryParams.bpm_max) {
      updates.tempoMax = queryParams.bpm_max;
    }

    // categories broken into mood/genre/instrument for music
    if (
      updates.contentType === SearchFilterContentTypes.Music &&
      queryParams.categories
    ) {
      updates.musicMoods = [];
      updates.musicGenres = [];
      updates.musicInstruments = [];
      queryParams.categories.split('_').forEach((urlId) => {
        const mood = moods.find((m) => m.urlId === urlId);
        const genre = mood ? null : genres.find((g) => g.urlId === urlId);
        const instrument =
          mood || genre
            ? null
            : musicInstruments.find((i) => i.urlId === urlId);

        if (mood) {
          updates.musicMoods.push(mood.categoryId);
        } else if (genre) {
          updates.musicGenres.push(genre.categoryId);
        } else if (instrument) {
          updates.musicInstruments.push(instrument.categoryId);
        }
      });
    }

    // categories for sfx
    if (
      updates.contentType === SearchFilterContentTypes.Sound_effects &&
      queryParams.categories
    ) {
      updates.sfxCategories = queryParams.categories
        .split('_')
        .map((urlId) =>
          sfxCategories.find((category) => category.urlId === urlId)
        )
        .filter(Boolean)
        .map(({ categoryId }) => categoryId);
    }

    // vocals
    if (typeof queryParams.has_vocals === 'boolean') {
      updates.vocalType = queryParams.has_vocals;
    }

    // artists
    if (queryParams.portal_artist_ids && state?.artists) {
      updates.artists = state.artists;
    }

    // search similar id
    if (queryParams.search_similar_id) {
      updates.similarTo = queryParams.search_similar_id;
      updates.searchSimilarTitle = state.searchSimilarTitle;
    }

    // search term
    const searchTerm = /\/audio\/search\/(.*)/.exec(pathname)?.[1];
    if (searchTerm) {
      updates.searchTerm = getDecodedSearchTerm(searchTerm);
    }

    return new AudioSearchOptions().update(updates);
  }, [pathname, search, key]);

  return selectedSearchFilterOptions;
}
