import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { initiateFetchWorkflow } from '../actions/SearchActions';
import useAudioSearchOptionsFromLocation from '../hooks/useAudioSearchOptionsFromLocation';
import useCaptureInitialAudioSearchOptionsAsHistoryState from '../hooks/useCaptureInitialAudioSearchOptionsAsHistoryState';
import AudioMenuContainer from './AudioMenuContainer';
import SearchContainer from './SearchContainer';
import AudioAppliedFilters from './appliedFilters/AudioAppliedFilters';

const AudioSearchContainer = (): JSX.Element => {
  useCaptureInitialAudioSearchOptionsAsHistoryState();

  const searchOptions = useAudioSearchOptionsFromLocation();

  const searchOptionsRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchOptionsRef.current) {
      dispatch(initiateFetchWorkflow(searchOptions));
    }
    searchOptionsRef.current = searchOptions;
  }, [searchOptions]);

  return (
    <SearchContainer
      renderAppliedFilters={() => <AudioAppliedFilters />}
      menuContainer={AudioMenuContainer}
    />
  );
};

export default AudioSearchContainer;
