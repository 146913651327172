import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setAudioFiltersChanged } from '../../../../Audio/actions/AudioActions';
import SiteConstants from '../../../../common/SiteConstants/SiteConstants';
import { getArtistFullName } from '../../../../common/utils';
import { searchOrigins } from '../../../Shared/constants';
import {
  hideSubMenu,
  updateSearchOptionsAndRefresh,
} from '../../actions/SearchActions';
import AppliedFilter from '../../components/appliedFilters/AppliedFilter';
import ContentType from '../../components/appliedFilters/ContentType';
import Duration from '../../components/appliedFilters/Duration';
import ResultCount from '../../components/appliedFilters/ResultCount';
import Tempo from '../../components/appliedFilters/Tempo';
import Vocals from '../../components/appliedFilters/Vocals';
import {
  genres as genreOptions,
  moods as moodOptions,
  musicInstruments as musicInstrumentOptions,
  sfxCategories as sfxCategoryOptions,
} from '../../entities/AudioSearchFilterOptions';
import SearchFilterOptions, {
  ALL_AUDIO_CONTENT_TYPE,
} from '../../entities/SearchFilterOptions';
import {
  selectSearchIsLoading,
  selectPagination,
  selectSearchFilterOptions,
  selectShouldUpdateSearchFilters,
  selectSuggestionsQuery,
} from '../../selectors/searchSelectors';
import { getOptionLabel } from './AppliedFiltersUtils';

import './AppliedFilters.less';

class AudioAppliedFilters extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.shouldUpdateSearchFilters !== false;
  }

  render() {
    return (
      <div className="applied-filters md:flex flex flex-nowrap md:flex-wrap">
        {this.renderSearchTerm()}
        {this.renderSearchSimilar()}
        {this.renderContentType()}
        {this.renderArtists()}
        {this.renderCategories()}
        {this.renderVocals()}
        {this.renderTempo()}
        {this.renderDuration()}
        {this.renderResultCount()}
      </div>
    );
  }

  renderSearchTerm() {
    const { searchTerm } = this.props.selectedSearchFilterOptions;
    const suggestionsQuery = this.props.selectedSuggestionsQuery;

    const displaySearchTerm = suggestionsQuery ? suggestionsQuery : searchTerm;

    if (displaySearchTerm) {
      const label = `"${displaySearchTerm}"`;
      return (
        <AppliedFilter
          label={label}
          onClear={() => {
            this.updateFilters({ searchTerm: '' });
          }}
        />
      );
    } else {
      return null;
    }
  }

  renderSearchSimilar() {
    const { searchSimilarTitle } = this.props.selectedSearchFilterOptions;
    if (searchSimilarTitle) {
      const label = `Tracks similar to: ${searchSimilarTitle}`;
      return (
        <AppliedFilter
          label={label}
          onClear={() => {
            this.updateFilters({ similarTo: '', searchSimilarTitle: '' });
          }}
        />
      );
    } else {
      return null;
    }
  }

  renderContentType() {
    const { contentType } = this.props.selectedSearchFilterOptions;

    if (contentType === ALL_AUDIO_CONTENT_TYPE) {
      return null;
    } else {
      return (
        <ContentType
          appliedContentType={contentType}
          allContentTypes={SearchFilterOptions.getContentTypes()}
          onClear={() =>
            this.updateFilters({ contentType: ALL_AUDIO_CONTENT_TYPE })
          }
        />
      );
    }
  }

  renderArtists() {
    const { artists } = this.props.selectedSearchFilterOptions;

    if (!artists) {
      return null;
    } else {
      return artists.map((artist) => {
        return (
          <AppliedFilter
            key={artist.id}
            secondaryClassName={`${artist.firstName}${artist.lastName || ''}`
              .split(' ')
              .join('')}
            label={artist.alias || getArtistFullName(artist)}
            onClear={() => this.removeArtist(artist.id)}
          />
        );
      });
    }
  }

  removeArtist(artistId) {
    const { artists } = this.props.selectedSearchFilterOptions;
    const updatedArtists = artists.filter((artist) => artist.id !== artistId);

    this.updateFilters({ artists: updatedArtists });
  }

  renderVocals() {
    const { vocalType, contentType } = this.props.selectedSearchFilterOptions;

    if (contentType !== 'music') {
      return null;
    } else {
      return (
        <Vocals
          hasVocals={vocalType}
          onClear={() => this.updateFilters({ vocalType: '' })}
        />
      );
    }
  }

  renderTempo() {
    const { tempoMin, tempoMax, contentType } =
      this.props.selectedSearchFilterOptions;

    if (contentType === ALL_AUDIO_CONTENT_TYPE || contentType === 'sfx') {
      return null;
    } else {
      return (
        <Tempo
          tempoMin={tempoMin}
          tempoMax={tempoMax}
          onClear={() => this.updateFilters({ tempoMin: null, tempoMax: null })}
        />
      );
    }
  }

  renderDuration() {
    const { minDuration, maxDuration } = this.props.selectedSearchFilterOptions;

    return (
      <Duration
        minDuration={parseInt(minDuration)}
        maxDuration={parseInt(maxDuration)}
        onClear={() =>
          this.updateFilters({ minDuration: null, maxDuration: null })
        }
      />
    );
  }

  renderResultCount() {
    const { numberOfResults, selectedSearchFilterOptions, resultsPerPage } =
      this.props;
    const resultsCount =
      selectedSearchFilterOptions.similarTo && numberOfResults > resultsPerPage
        ? resultsPerPage
        : numberOfResults;

    if (this.shouldHideResultCount()) {
      return null;
    } else {
      return <ResultCount numberOfResults={resultsCount} />;
    }
  }

  shouldHideResultCount() {
    const { isLoading, selectedSearchFilterOptions } = this.props;

    if (isLoading || !selectedSearchFilterOptions) {
      return true;
    } else {
      const { contentType, searchTerm, minDuration, maxDuration, artists } =
        selectedSearchFilterOptions;

      return (
        contentType === ALL_AUDIO_CONTENT_TYPE &&
        !searchTerm &&
        minDuration === SiteConstants.getInstance().getMinDuration() &&
        maxDuration === SiteConstants.getInstance().getMaxDuration() &&
        artists === []
      );
    }
  }

  renderCategories() {
    const appliedCategories = this.getCategoriesForContentType();

    if (!appliedCategories) {
      return;
    }

    return appliedCategories.map((filter) => {
      return (
        <AppliedFilter
          key={filter.name}
          label={filter.name}
          secondaryClassName={filter.name.split(' ').join('')}
          onClear={filter.action}
        />
      );
    });
  }

  getCategoriesForContentType() {
    switch (this.props.selectedSearchFilterOptions.contentType) {
      case ALL_AUDIO_CONTENT_TYPE:
        return [];
      case 'music':
        return this.getMusicCategories();
      case 'sfx':
        return this.getSfxCategories();
    }

    return null;
  }

  getMusicCategories() {
    const { musicMoods, musicGenres, musicInstruments } =
      this.props.selectedSearchFilterOptions;

    const appliedMoods = this.getCategories(
      musicMoods,
      moodOptions,
      'musicMoods'
    );
    const appliedGenres = this.getCategories(
      musicGenres,
      genreOptions,
      'musicGenres'
    );
    const appliedInstruments = this.getCategories(
      musicInstruments,
      musicInstrumentOptions,
      'musicInstruments'
    );

    return [...appliedMoods, ...appliedGenres, ...appliedInstruments];
  }

  getSfxCategories() {
    const { sfxCategories } = this.props.selectedSearchFilterOptions;
    return this.getCategories(
      sfxCategories,
      sfxCategoryOptions,
      'sfxCategories'
    );
  }

  getCategories(selectedOptions, allOptions, filterName) {
    const appliedCategories = [];

    for (let id of selectedOptions) {
      const appliedOption = {
        id,
        name: getOptionLabel(id, allOptions),
        action: () => {
          this.removeCategory(filterName, id);
        },
      };

      appliedCategories.push(appliedOption);
    }

    return appliedCategories;
  }

  removeCategory = (filterName, id) => {
    const propertiesToChange = {
      [filterName]: this.props.selectedSearchFilterOptions[filterName].filter(
        (option) => option !== id
      ),
    };

    this.updateFilters(propertiesToChange);
  };

  updateFilters = (properties) => {
    const contentTypeDidChange =
      properties.contentType &&
      properties.contentType !==
        this.props.selectedSearchFilterOptions.contentType;

    const propertiesToChange = {
      page: 1,
      searchOrigin: searchOrigins.APPLIED_FILTERS,
      isPagination: false,
      ...properties,
    };

    if (contentTypeDidChange) {
      propertiesToChange.musicGenres = [];
      propertiesToChange.musicInstruments = [];
      propertiesToChange.musicMoods = [];
      propertiesToChange.sfxCategories = [];
    }

    const newSearchOptions =
      this.props.selectedSearchFilterOptions.update(propertiesToChange);
    this.props.dispatch(setAudioFiltersChanged(true));
    this.props.dispatch(updateSearchOptionsAndRefresh(newSearchOptions));
    contentTypeDidChange && this.props.dispatch(hideSubMenu());
  };
}

function mapStateToProps(state) {
  const pagination = selectPagination(state);
  return {
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    shouldUpdateSearchFilters: selectShouldUpdateSearchFilters(state),
    numberOfResults: pagination.totalResults,
    resultsPerPage: pagination.resultsPerPage,
    isLoading: selectSearchIsLoading(state),
    selectedSuggestionsQuery: selectSuggestionsQuery(state),
  };
}

export default connect(mapStateToProps)(AudioAppliedFilters);
