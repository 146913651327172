import React from 'react';

import SiteConstants from '../../../../common/SiteConstants/SiteConstants';
import { convertSecondsToMMSS } from '../../../../common/utils';
import AppliedFilter from './AppliedFilter';

interface Props {
  minDuration: number;
  maxDuration: number;
  highestDuration: number;
  onClear: () => void;
}

const Duration = ({ minDuration, maxDuration, onClear }: Props) => {
  const highestDuration = SiteConstants.getInstance().getMaxDuration();

  if (
    (minDuration && minDuration > 0) ||
    (maxDuration && maxDuration < highestDuration)
  ) {
    const formattedMin = minDuration
      ? convertSecondsToMMSS(minDuration)
      : convertSecondsToMMSS(SiteConstants.getInstance().getMinDuration());
    const formattedMax = maxDuration
      ? convertSecondsToMMSS(maxDuration, highestDuration)
      : convertSecondsToMMSS(highestDuration, highestDuration);
    const label = `Duration: ${formattedMin} - ${formattedMax}`;

    return (
      <AppliedFilter
        label={label}
        onClear={onClear}
        secondaryClassName="Duration"
      />
    );
  } else {
    return null;
  }
};

export default Duration;
