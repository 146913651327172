import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { twMerge } from 'tailwind-merge';

import { setAudioFiltersChanged } from '../../../Audio/actions/AudioActions';
import SiteConstants from '../../../common/SiteConstants/SiteConstants';
import { searchOrigins } from '../../Shared/constants';
import { SearchFeatures } from '../SearchTypes';
import { updateSearchOptionsAndRefresh } from '../actions/SearchActions';
import ToggleMenuButton from '../components/ToggleMenuButton';
import ClearFilters from '../components/filters/ClearFilters';
import CollapsibleCheckboxFilter from '../components/filters/CollapsibleCheckboxFilter';
import CollapsibleRadioFilter from '../components/filters/CollapsibleRadioFilter';
import DurationSliderFilter from '../components/filters/DurationSliderFilter';
import TempoSliderFilter from '../components/filters/TempoSliderFilter';
import {
  genres,
  moods,
  musicInstruments as musicInstrumentOptions,
  sfxCategories as sfxCategoryOptions,
  vocalType as vocalTypeOptions,
} from '../entities/AudioSearchFilterOptions';
import SearchFilterOptions, {
  ALL_AUDIO_CONTENT_TYPE,
} from '../entities/SearchFilterOptions';
import SearchOptions from '../entities/SearchOptions';
import {
  selectSearchFeatures,
  selectSearchFilterOptions,
  selectShouldUpdateSearchFilters,
} from '../selectors/searchSelectors';
import { AudioSelectedSearchFilterOptions } from './MenuContainerInterfaces';

interface Props {
  shouldOpenSideMenu: boolean;
  selectedSearchFilterOptions: AudioSelectedSearchFilterOptions;
  closeSideMenu: () => void;
  showSideMenu: () => void;
  dispatch: ThunkDispatch<any, any, any>;
  features: SearchFeatures;
}

interface State {
  collapsed: Collapsed;
}

interface Collapsed {
  contentType: boolean;
  sfxCategories: boolean;
  musicMoods: boolean;
  musicGenres: boolean;
  musicInstruments: boolean;
  vocalType: boolean;
  tempo: boolean;
  duration: boolean;
}

class AudioMenuContainer extends Component<Props, State> {
  state = {
    collapsed: {
      contentType: false,
      sfxCategories: true,
      musicMoods: true,
      musicGenres: true,
      musicInstruments: true,
      vocalType: true,
      tempo: true,
      duration: true,
    },
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.shouldUpdateSearchFilters !== false;
  }

  render() {
    const sideMenuClass = twMerge(
      this.props.features.useRemainingSearchUI ? '' : 'search-ui-border',
      'audioblocks side-menu hidden md:block',
      this.props.shouldOpenSideMenu ? 'open-side-menu' : 'close-side-menu'
    );

    return (
      <div className={sideMenuClass}>
        <div className="side-menu-stickyContainer">
          <ClearFilters
            isOpen={this.props.shouldOpenSideMenu}
            closeSideMenu={this.props.closeSideMenu}
            showSideMenu={this.props.showSideMenu}
            clearFilters={this.clearFilters}
          />
          <div className="side-menu-filters">
            {this.renderContentTypeFilter()}

            {this.renderFiltersForContentType()}
          </div>
        </div>
      </div>
    );
  }

  renderToggleMenuButton() {
    return (
      <ToggleMenuButton
        isOpen={this.props.shouldOpenSideMenu}
        closeSideMenu={this.props.closeSideMenu}
        showSideMenu={this.props.showSideMenu}
      />
    );
  }

  renderContentTypeFilter() {
    const { selectedSearchFilterOptions } = this.props;

    return (
      <React.Fragment>
        <CollapsibleRadioFilter
          checked={selectedSearchFilterOptions.contentType}
          filterName="contentType"
          filterValues={SearchFilterOptions.getContentTypes()}
          handleFilterChanged={this.handleFilterChanged}
          name="Media Type"
          isCollapsed={this.state.collapsed.contentType}
          toggleCollapsed={() => this.toggleCollapsed('contentType')}
        />
      </React.Fragment>
    );
  }

  renderFiltersForContentType() {
    const { contentType } = this.props.selectedSearchFilterOptions;

    switch (contentType) {
      case 'music':
        return this.renderMusicFilters();
      case 'sfx':
        return this.renderSfxFilters();
      default:
        return this.renderMusicFilters();
    }
  }

  renderMusicFilters() {
    return (
      <React.Fragment>
        {this.renderMusicMoodFilter()}
        {this.renderMusicGenreFilter()}
        {this.renderMusicInstrumentFilter()}
        {this.renderMusicVocalFilter()}
        {this.renderTempoFilter()}
        {this.renderDurationFilter()}
        {this.renderSfxCategoryFilter()}
      </React.Fragment>
    );
  }

  renderSfxFilters() {
    return (
      <React.Fragment>
        {this.renderDurationFilter()}
        {this.renderSfxCategoryFilter()}
        {this.renderMusicMoodFilter()}
        {this.renderMusicGenreFilter()}
        {this.renderMusicInstrumentFilter()}
        {this.renderMusicVocalFilter()}
        {this.renderTempoFilter()}
      </React.Fragment>
    );
  }

  renderSfxCategoryFilter() {
    const { sfxCategories, contentType } =
      this.props.selectedSearchFilterOptions;

    return (
      <React.Fragment>
        <CollapsibleCheckboxFilter
          groupName="sfxCategories"
          handleFilterChanged={this.handleFilterChanged}
          name="Categories"
          options={sfxCategoryOptions}
          optionType="checkbox"
          isCollapsed={this.state.collapsed.sfxCategories}
          toggleCollapsed={() => this.toggleCollapsed('sfxCategories')}
          checkedValues={sfxCategories}
          invalidSelectionMessage={
            contentType === 'sfx'
              ? null
              : 'Select Sound Effects to see Categories'
          }
        />
      </React.Fragment>
    );
  }

  renderMusicMoodFilter() {
    const { musicMoods, contentType } = this.props.selectedSearchFilterOptions;

    return (
      <React.Fragment>
        <CollapsibleCheckboxFilter
          groupName="musicMoods"
          handleFilterChanged={this.handleFilterChanged}
          name="Moods"
          options={moods}
          optionType="checkbox"
          isCollapsed={this.state.collapsed.musicMoods}
          toggleCollapsed={() => this.toggleCollapsed('musicMoods')}
          checkedValues={musicMoods}
          invalidSelectionMessage={
            contentType === 'music' ? null : 'Select Music to see Moods'
          }
        />
      </React.Fragment>
    );
  }

  renderMusicGenreFilter() {
    const { musicGenres, contentType } = this.props.selectedSearchFilterOptions;

    return (
      <React.Fragment>
        <CollapsibleCheckboxFilter
          groupName="musicGenres"
          handleFilterChanged={this.handleFilterChanged}
          name="Genres"
          options={genres}
          optionType="checkbox"
          isCollapsed={this.state.collapsed.musicGenres}
          toggleCollapsed={() => this.toggleCollapsed('musicGenres')}
          checkedValues={musicGenres}
          invalidSelectionMessage={
            contentType === 'music' ? null : 'Select Music to see Genres'
          }
        />
      </React.Fragment>
    );
  }

  renderMusicInstrumentFilter() {
    const { musicInstruments } = this.props.selectedSearchFilterOptions;

    return (
      <React.Fragment>
        <CollapsibleCheckboxFilter
          groupName="musicInstruments"
          handleFilterChanged={this.handleFilterChanged}
          name="Instruments"
          options={musicInstrumentOptions}
          optionType="checkbox"
          isCollapsed={this.state.collapsed.musicInstruments}
          toggleCollapsed={() => this.toggleCollapsed('musicInstruments')}
          checkedValues={musicInstruments}
          invalidSelectionMessage={
            this.props.selectedSearchFilterOptions.contentType === 'music'
              ? null
              : 'Select Music to see Instruments'
          }
        />
      </React.Fragment>
    );
  }

  renderMusicVocalFilter() {
    const { vocalType, contentType } = this.props.selectedSearchFilterOptions;

    return (
      <React.Fragment>
        <CollapsibleRadioFilter
          checked={vocalType}
          filterName="vocalType"
          filterValues={vocalTypeOptions}
          handleFilterChanged={this.handleFilterChanged}
          name="Vocals"
          hideValue={vocalType === ''}
          isCollapsed={this.state.collapsed.vocalType}
          toggleCollapsed={() => this.toggleCollapsed('vocalType')}
          invalidSelectionMessage={
            contentType === 'music' ? null : 'Select Music to see Vocals'
          }
        />
      </React.Fragment>
    );
  }

  renderTempoFilter() {
    const { tempoMin, tempoMax, contentType } =
      this.props.selectedSearchFilterOptions;
    const isAllAudio = contentType === ALL_AUDIO_CONTENT_TYPE;

    return (
      <>
        <TempoSliderFilter
          minRangeValue={parseInt(SiteConstants.getInstance().getMinTempo())}
          maxRangeValue={parseInt(SiteConstants.getInstance().getMaxTempo())}
          stepInterval={1}
          minValue={tempoMin}
          maxValue={tempoMax}
          name="Tempo"
          isCollapsed={this.state.collapsed.tempo}
          toggleCollapsed={() => this.toggleCollapsed('tempo')}
          invalidSelectionMessage={
            contentType === 'sfx' || isAllAudio
              ? 'Select Music to set Tempo'
              : null
          }
        />
      </>
    );
  }

  renderDurationFilter() {
    return (
      <React.Fragment>
        <DurationSliderFilter
          minRangeValue={SiteConstants.getInstance().getMinDuration()}
          maxRangeValue={SiteConstants.getInstance().getMaxDuration()}
          stepInterval={1}
          minValue={parseInt(
            this.props.selectedSearchFilterOptions.minDuration
          )}
          maxValue={parseInt(
            this.props.selectedSearchFilterOptions.maxDuration
          )}
          name="Duration"
          isCollapsed={this.state.collapsed.duration}
          toggleCollapsed={() => this.toggleCollapsed('duration')}
        />
      </React.Fragment>
    );
  }

  clearFilters = (e) => {
    e.preventDefault();

    let newSearchOptions = SearchOptions.getDefaultSearchOptions();
    newSearchOptions = newSearchOptions.update({
      searchTerm: this.props.selectedSearchFilterOptions.searchTerm,
    });

    this.props.dispatch(setAudioFiltersChanged(true));
    this.props.dispatch(updateSearchOptionsAndRefresh(newSearchOptions));
  };

  handleFilterChanged = (name, value) => {
    const propertiesToChange = {
      [name]: value,
      page: 1, // Go back to page 1 when any filter is changed
      searchOrigin: searchOrigins.FILTERS,
      isPagination: false,
    };

    if (name === 'contentType') {
      Object.assign(propertiesToChange, {
        contributorIds: [],
        contentType: value,
        similarTo: null,
        searchSimilarTitle: null,
        // Reassert the content class in case the NavSearchContainer selection has changed
        contentClass: SearchFilterOptions.getContentClassFromContentType(value),
        musicGenres: [],
        musicInstruments: [],
        musicMoods: [],
        sfxCategories: [],
      });
    }

    const newSearchOptions =
      this.props.selectedSearchFilterOptions.update(propertiesToChange);
    this.props.dispatch(setAudioFiltersChanged(true));
    this.props.dispatch(updateSearchOptionsAndRefresh(newSearchOptions));
  };

  toggleCollapsed(name) {
    const collapsed = this.state.collapsed;
    collapsed[name] = !collapsed[name];

    this.setState({ collapsed });
  }
}

function mapStateToProps(state) {
  return {
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    shouldUpdateSearchFilters: selectShouldUpdateSearchFilters(state),
    features: selectSearchFeatures(state),
  };
}

export default connect(mapStateToProps)(AudioMenuContainer);
